import React, { Component } from "react"

import Link from "../../utils/link"

import Logo from "../../assets/images/logo.svg"
import "./hero.scss"

class Hero extends Component {

  state = {
    src: null
  }

  componentDidMount() {

    const {
      landingVideoUrl,
      landingVideoUrlMobile,
    } = this.props

    const src =
      typeof window !== "undefined" && window.innerWidth > 900
        ? landingVideoUrl
        : landingVideoUrlMobile

    this.setState({ src })
  }

  render() {
    let { src } = this.state

    const {
      landingVideoPlaceholder,
    } = this.props

    return (
      <section className="hero is-fullheight">
        <video
          src={src}
          playsInline
          muted
          loop
          autoPlay
          poster={landingVideoPlaceholder.localFile.childImageSharp.original.src}
        />
        <div className="hero__body">
          <div className="hero__body__logo">
            <img src={Logo} alt="Flip Video" />
          </div>
        </div>
        <div className="hero__footer">
          <Link to="/sectors/" className="hero__link">
            Enter Site
          </Link>
        </div>
      </section>
    )
  }
}

export default Hero
