import React from "react"
import { graphql } from "gatsby"
import he from "he"

import SEO from "../components/seo"
import Header from "../components/header"
import Hero from "../components/home/Hero"
import BottomBar from "../components/BottomBar"

const IndexPage = ({ data }) => {
  const page = data.wpPage

  return (
    <>
      <SEO
        path="/"
        title={he.decode(page.seo.title)}
        description={he.decode(page.seo.metaDesc)}
        bodyClass="home"
      />
      <Header noLogo />
      <Hero {...page.acf} />
    </>
  )
}

export const pageQuery = graphql`
  query {
    wpPage(databaseId: { eq: 299 }) {
      seo {
        title
        metaDesc
      }
      acf {
        landingVideoUrl
        landingVideoUrlMobile
        landingVideoPlaceholder {
          altText
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
